<script setup lang="ts">
defineProps<{
  absolute?: boolean;
}>();

const nouns = [
  "code",
  "tears",
  "coffee",
  "beers",
  "late nights",
  "swearing",
];

const grumbles = [
  "please stop doing that",
  "seriously, stop, it tickles",
  "enough with the jabbing already",
  "will you stop that!?",
  "you just don't learn do you",
  "do that one more time...",
];

const currentNounIndex = ref( 0 );
const currentGrumbleIndex = ref( 0 );
const totalNounHovers = ref( 0 );
const showGrumble = ref( false );
const showLeaving = ref( false );

const grumbleInterval = 10;

const currentNoun = computed( () => nouns[currentNounIndex.value] );
const currentGrumble = computed( () => grumbles[currentGrumbleIndex.value] );
const lockHover = ref( false );
const lockClick = ref( false );

const route = useRoute();

function rotateNoun () {
  if ( totalNounHovers.value === grumbleInterval ) {
    totalNounHovers.value = 0;

    if ( currentGrumbleIndex.value === grumbles.length ) {
      showLeaving.value = true;
    }
    else {
      showGrumble.value = true;
      window.setTimeout( () => {
        showGrumble.value = false;
        lockHover.value = true;
        rotateGrumble();

        window.setTimeout( () => {
          lockHover.value = false;
        }, 300 );
      }, 1000 );
    }
  }

  currentNounIndex.value = currentNounIndex.value !== nouns.length - 1
    ? currentNounIndex.value + 1
    : 0;
}

function rotateGrumble () {
  currentGrumbleIndex.value = currentGrumbleIndex.value !== grumbles.length
    ? currentGrumbleIndex.value + 1
    : 0;
}

watch( () => route.name, () => {
  rotateNoun();
} );

function onMouseHover () {
  if ( !showGrumble.value && !lockHover.value ) {
    totalNounHovers.value += 1;
    rotateNoun();
  }

  lockClick.value = true;
  window.setTimeout( () => {
    lockClick.value = false;
  }, 300 );
}

function onClick () {
  if ( lockClick.value ) {
    return;
  }

  if ( showLeaving.value ) {
    showLeaving.value = false;
    showGrumble.value = false;
    totalNounHovers.value = 0;
    currentGrumbleIndex.value = 0;
    currentNounIndex.value = 0;
  }
  else {
    onMouseHover();
  }
}
</script>

<template>
  <footer
    class="bg-[#041024]/80 backdrop-blur"
    :class="{ 'absolute bottom-0 left-0 right-0 z-20': absolute, 'relative z-20': !absolute }">
    <div class="container py-6 text-center">
      <p>
        <span class="flex justify-center items-center gap-1">
          Created by Daniel with

          <span class="relative inline-flex items-center justify-center leading-none mt-0.5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="inline-block w-4 h-4 text-bittersweet-500"
              fill="currentColor"
              viewBox="0 0 512 512">
              <path d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z" />
            </svg>️

            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="inline-block w-4 h-4 text-bittersweet-500 hover:animate-ping absolute inset-0 hover:opacity-100 hover:cursor-pointer"
              fill="currentColor"
              viewBox="0 0 512 512">
              <path d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z" />
            </svg>️
          </span>
        </span>
        <span
          class="text-sm text-[#e8c7f5] font-medium cursor-pointer select-none"
          @mouseover="onMouseHover"
          @click="onClick">
          <span
            v-if="showLeaving"
            class="leaving-text">
            screw you, I'm leaving. find someone else to harass
          </span>
          <span
            v-else-if="showGrumble"
            class="exasperated-text">
            {{ currentGrumble }}
          </span>
          <template v-else>
            (and a lot of {{ currentNoun }})
          </template>
        </span>
      </p>
    </div>
  </footer>
</template>

<style scoped>
@keyframes shakeAndGrow {
  0%, 100% {
    transform: scale(1) translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: scale(1.1) translateX(-5px);
  }
  20%, 40%, 60%, 80% {
    transform: scale(1.1) translateX(5px);
  }
}

.exasperated-text {
  display: inline-block;
  animation: shakeAndGrow 0.8s ease infinite;
  will-change: transform;
}

@keyframes leaveScreen {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(-50px);
    opacity: 0.5;
  }
  100% {
    transform: translateY(-100vh); /* Move it out of the viewport vertically */
    opacity: 0;
  }
}

.leaving-text {
  display: inline-block;
  animation: leaveScreen 2s ease-in forwards 2s;
  cursor: not-allowed;
  will-change: transform, opacity;
  color: #e08a70
}
</style>
